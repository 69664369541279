class TypingText extends HTMLElement {
    constructor() {
        super();
        this.strings = [];
        this.options = {
            typeSpeed: 0.07,
            deleteSpeed: 0.03,
            delayBetweenStrings: 1000,
            loop: true,
            cursorChar: '|'
        };
        
        this.cursorElement = document.createElement('span');
        this.cursorElement.textContent = this.options.cursorChar;
        this.cursorElement.style.animation = 'cursor-blink 1s step-end infinite';
        this.cursorElement.style.color = 'var(--tt-main-color)';
    }

    connectedCallback() {
        // Parse attributes
        const stringsAttr = this.getAttribute('strings');
        if (stringsAttr) {
            this.strings = JSON.parse(stringsAttr);
        }

        // Parse options
        const speed = this.getAttribute('type-speed');
        const deleteSpeed = this.getAttribute('delete-speed');
        const delay = this.getAttribute('delay');
        const loop = this.getAttribute('loop');

        if (speed) this.options.typeSpeed = parseFloat(speed);
        if (deleteSpeed) this.options.deleteSpeed = parseFloat(deleteSpeed);
        if (delay) this.options.delayBetweenStrings = parseFloat(delay);
        if (loop !== null) this.options.loop = loop !== 'false';

        // Add CSS for cursor animation
        const style = document.createElement('style');
        style.textContent = `
            @keyframes cursor-blink {
                0%, 100% { opacity: 1; }
                50% { opacity: 0; }
            }
        `;
        document.head.appendChild(style);

        // Add cursor element to component
        this.appendChild(this.cursorElement);
        
        this.startAnimation();
    }

    async startAnimation() {
        let currentIndex = 0;

        const animateText = async () => {
            const string = this.strings[currentIndex];
            
            // Type the string
            for (let i = 0; i <= string.length; i++) {
                this.textContent = string.substring(0, i);
                this.appendChild(this.cursorElement);
                await new Promise(resolve => setTimeout(resolve, this.options.typeSpeed * 1000));
            }

            await new Promise(resolve => setTimeout(resolve, this.options.delayBetweenStrings));

            // Delete the string
            for (let i = string.length; i >= 0; i--) {
                this.textContent = string.substring(0, i);
                this.appendChild(this.cursorElement);
                await new Promise(resolve => setTimeout(resolve, this.options.deleteSpeed * 1000));
            }

            currentIndex = (currentIndex + 1) % this.strings.length;
            if (this.options.loop) {
                animateText();
            }
        };

        animateText();
    }
}

customElements.define('typing-text', TypingText);